import Vue from 'vue';
import LottieAnimation from 'lottie-vuejs/src/LottieAnimation.vue';
import App from './App.vue';
import './registerServiceWorker';
import router from './router';
import store from './store';

Vue.use(LottieAnimation);

Vue.config.productionTip = false;

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount('#app');
