<template>
<div @click="$emit('click')" @keyup="keyup">
  <lottie-animation
    :path="src"
    :loop="loop"
    :width="width*1"
    :height="height*1"
    @AnimControl="setAnimController"
  />
</div>
</template>

<script>
import LottieAnimation from 'lottie-vuejs/src/LottieAnimation.vue';

export default {
  name: 'LottieTest',
  components: {
    LottieAnimation,
  },
  props: {
    src: String,
    loop: {
      type: Boolean,
      default: false,
    },
    width: {
      type: [String, Number],
      default: -1,
    },
    height: {
      type: [String, Number],
      default: -1,
    },
  },
  data() {
    return {
      animation: null,
    };
  },
  methods: {
    /* eslint-disable object-shorthand */
    setAnimController: function (event) {
      this.animation = event;
    },
    /* eslint-enable  object-shorthand */
    keyup: () => {
      // ni idea aun
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}
</style>
