<template>
  <div class="hello">
    <h1>{{ msg }}</h1>

    <h1>LottieTest</h1>
    <LottieTest
      :src="'lottie/'+i+'.json'"
      width="300"
      height="300"
      loop
      @click="cambiar"
    />
  </div>
</template>

<script>
import LottieTest from './LottieTest.vue';

export default {
  name: 'HelloWorld',
  props: {
    msg: String,
  },
  components: {
    LottieTest,
  },
  data() {
    return {
      i: 1,
    };
  },
  methods: {
    /* eslint-disable object-shorthand */
    cambiar: function () {
      this.i = ((this.i + 1) % 3) + 1;
    },
    /* eslint-enable object-shorthand */
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}
</style>
